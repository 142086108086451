import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../../Redux/Slice/ResetPasswordSlice";

const ResetPassword = () => {
  const { token } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const email = queryParams.get("email");

  const cleanToken = token.startsWith(".") ? token.slice(1) : token;

  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [resetSuccess, setResetSuccess] = useState(false); // State for reset success
  const dispatch = useDispatch();

  const handleReset = async () => {
    try {
      const action = await dispatch(
        resetPassword({ token: cleanToken, email, password, confirmPassword })
      );

      if (action.payload.error) {
        setErrorMessage(action.payload.error.message || "Failed");
        return;
      }

      setErrorMessage("");
      setResetSuccess(true); // Set reset success to true
      setTimeout(() => {
        navigate("/login");
      }, 3000); // Redirect to login page after 3 seconds
    } catch (error) {
      console.error("Error during login:", error);
      setErrorMessage("Failed");
    }
  };

  return (
    <div style={containerStyle}>
      <h1 style={{ color: "#fff" }}>Reset Password</h1>
      <form style={formStyle}>
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={inputStyle}
        />
        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          style={inputStyle}
        />
        <button type="button" onClick={handleReset} style={buttonStyle}>
          Reset
        </button>

        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        {resetSuccess && (
          <p style={{ color: "green" }}>Reset successful! Redirecting...</p>
        )}
      </form>
    </div>
  );
};

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  background: "url('https://wallpapercave.com/wp/wp2015619.jpg')",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.6)",
  color: "white",
};

const formStyle = {
  display: "flex",
  flexDirection: "column",
  width: "300px",
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  padding: "20px",
  borderRadius: "8px",
};

const inputStyle = {
  margin: "10px",
  padding: "8px",
  fontSize: "16px",
};

const buttonStyle = {
  margin: "10px",
  padding: "10px",
  fontSize: "18px",
  backgroundColor: "#141b2d",
  color: "white",
  cursor: "pointer",
};

export default ResetPassword;
