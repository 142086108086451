import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";
import { toast } from "react-toastify";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const updatePjp = createAsyncThunk(
  "auth/updatePjp",
  async (credentials) => {
    console.log("Form Data Received:", credentials);
    const token = getTokenFromLocalStorage();

    const res = await fetch(`${apiUrl}/sales/pjp-report/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
      body: JSON.stringify(credentials),
    });
    const data = await res.json();
    if (data.status) {
      toast.success(data.message);
      return data;
    }
    return data;
  }
);

const pjpUpdateSlice = createSlice({
  name: "updatePjp",
  initialState: {
    updatePjp: null,
    isAuthenticated: false,
    isLoader: false,
    isError: false,
    token: null,
  },
  extraReducers: (builder) => {
    builder.updateCase(updatePjp.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.updateCase(updatePjp.fulfilled, (state, action) => {
      state.isLoader = false;
      state.isAuthenticated = true;
      state.updatePjp = action.payload.data;
      state.token = action.payload.token;
    });
    builder.updateCase(updatePjp.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default pjpUpdateSlice.reducer;
