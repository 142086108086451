// Import necessary dependencies
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../../apiConfig";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

// Async thunk for adding a company
export const addTracker = createAsyncThunk(
  "Tracker/addTracker",
  async (formData) => {
    try {
      const token = getTokenFromLocalStorage();

      const response = await axios.post(
        `${apiUrl}/sales/user-trace/add`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;
      if (data.status) {
        // toast.success(data.message);
        return data;
      }
    } catch (error) {
      console.error("Error adding Tracker:", error);
      if (error.response && error.response.data && error.response.data.errors) {
        toast.error(error.response.data.errors);
      } else {
        toast.error("Error adding Tracker. Please try again.");
      }
      throw error; // Re-throw the error to be caught in the .catch() block
    }
  }
);

// Create a slice for the company state
const TrackerAddSlice = createSlice({
  name: "Tracker",
  initialState: {
    Tracker: null,
    isAuthenticated: false,
    isLoader: false,
    isError: false,
    token: null,
  },
  extraReducers: (builder) => {
    builder.addCase(addTracker.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(addTracker.fulfilled, (state, action) => {
      state.isLoader = false;
      state.isAuthenticated = true;
      state.Tracker = action.payload.data;
      state.token = action.payload.token;
    });
    builder.addCase(addTracker.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

// Export the reducer
export default TrackerAddSlice.reducer;
