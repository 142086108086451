import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import Topbar from "../global/Topbar";
import Calendar from "react-calendar";
import { fetchViewVisits } from "../../Redux/Slice/Visits/ViewVisitsSlice";
import redPinIcon from "../../Assets/images/pin.png";
import "react-calendar/dist/Calendar.css";

export default function VisitsOnMap() {
  const dispatch = useDispatch();

  const locationOnIcon = new L.Icon({
    iconUrl: redPinIcon,
    iconSize: [25, 30],
    iconAnchor: [15, 30],
    popupAnchor: [0, -30],
  });

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedCategory, setSelectedCategory] = useState(""); // State to store selected category
  const visits = useSelector((state) => state.ViewVisits.ViewVisitsData?.data);

  useEffect(() => {
    dispatch(fetchViewVisits(selectedDate, selectedCategory)); // Include selected category in fetching visits
  }, [dispatch, selectedDate, selectedCategory]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  // Map initialization
  const [mapBounds, setMapBounds] = useState(null);
  const [polylines, setPolylines] = useState([]);

  useEffect(() => {
    if (visits) {
      const validLocations = visits.filter(
        (visit) =>
          !isNaN(parseFloat(visit.visits.visit_latitude)) &&
          !isNaN(parseFloat(visit.visits.visit_longitude)) &&
          new Date(visit.visits.created_at).toDateString() ===
          selectedDate.toDateString() &&
          (selectedCategory === "" ||
            visit.visits.category === selectedCategory)
      );

      if (validLocations.length > 0) {
        const latLngs = validLocations.map((visit) => [
          parseFloat(visit.visits.visit_latitude),
          parseFloat(visit.visits.visit_longitude),
        ]);
        const bounds = L.latLngBounds(latLngs);
        setMapBounds(bounds);

        // Calculate polylines
        const lines = [];
        for (let i = 0; i < validLocations.length - 1; i++) {
          const start = [
            parseFloat(validLocations[i].visits.visit_latitude),
            parseFloat(validLocations[i].visits.visit_longitude),
          ];
          const end = [
            parseFloat(validLocations[i + 1].visits.visit_latitude),
            parseFloat(validLocations[i + 1].visits.visit_longitude),
          ];
          lines.push([start, end]);
        }
        setPolylines(lines);
      }
    }
  }, [visits, selectedDate, selectedCategory]);

  return (
    <>
      <Topbar />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px",
        }}
      >
        <div style={{ flex: "0 0 30%", marginRight: "20px" }}>
          <select
            value={selectedCategory}
            onChange={handleCategoryChange}
            style={{ width: "360px", height: "30px", borderRadius: "5px" }}
          >
            <option value="">All Categories</option>
            <option value="Dealer">Dealer</option>
            <option value="SubDealer">SubDealer</option>
            <option value="Architect">Architect</option>
            <option value="Builder">Builder</option>
            <option value="Contractor">Contractor</option>
            <option value="Competitor">Competitor</option>
            <option value="Other">Other</option>
          </select>
          <h4
            style={{
              color: "#ffffff",
              background: "#4d0054",
              padding: "10px",
              borderRadius: "5px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0)",
            }}
          >
            Filter By Date
          </h4>
          <Calendar onChange={handleDateChange} value={selectedDate} />
        </div>
        <div style={{ flex: "0 0 70%" }}>
          {mapBounds ? (
            <MapContainer
              bounds={mapBounds}
              style={{
                height: "600px",
                width: "95%",
                border: "2px solid #ccc",
              }} // Increased height and added border
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {polylines.map((line, index) => (
                <Polyline key={index} positions={line} color="blue" />
              ))}
              {visits?.map((visit) => {
                const latitude = parseFloat(visit.visits.visit_latitude);
                const longitude = parseFloat(visit.visits.visit_longitude);
                if (
                  isNaN(latitude) ||
                  isNaN(longitude) ||
                  new Date(visit.visits.created_at).toDateString() !==
                  selectedDate.toDateString() ||
                  (selectedCategory !== "" &&
                    visit.visits.category !== selectedCategory)
                ) {
                  // Skip markers with invalid latitude or longitude or visits not on the selected date or category
                  return null;
                }
                return (
                  <Marker
                    key={visit.id}
                    position={[latitude, longitude]}
                    icon={locationOnIcon}
                  >
                    <Popup>{visit.visits.firm_name}</Popup>
                  </Marker>
                );
              })}
            </MapContainer>
          ) : (
            <p>No Visits</p>
          )}
        </div>
      </div>
    </>
  );
}
