import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const fetchViewCategoryById = createAsyncThunk(
  "ViewCategoryById/fetchViewCategoryById",
  async (selectedCategoryId) => {
    const token = getTokenFromLocalStorage();
    console.log("selectedCategoryId", selectedCategoryId);
    const url = `${apiUrl}/sales/dealer/dealers/${selectedCategoryId}`;
    const res = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const final = await res.json();
    console.log("Response Data:", final);
    return final;
  }
);

const ViewCategorySliceById = createSlice({
  name: "ViewCategory",
  initialState: {
    ViewCategoryByIdData: null,
    isLoader: false,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchViewCategoryById.pending, (state) => {
      state.isLoader = true;
    });
    builder.addCase(fetchViewCategoryById.fulfilled, (state, action) => {
      state.isLoader = false;
      state.ViewCategoryByIdData = action.payload.data; // Assuming action.payload.data is the array of dealers
    });

    builder.addCase(fetchViewCategoryById.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default ViewCategorySliceById.reducer;
