import React from "react";
import { Card, CardContent, Typography, Button } from "@mui/material";
import Topbar from "../global/Topbar";
import { useDispatch, useSelector } from "react-redux";
import { fetchViewProfile } from "../../Redux/Slice/Profile/ViewProfileSlice";
import { Link } from "react-router-dom";

const Profile = () => {
  const dispatch = useDispatch();
  const ProfileData = useSelector((state) => state.ViewProfile.ViewProfileData);

  React.useEffect(() => {
    dispatch(fetchViewProfile());
  }, [dispatch]);

  const imageUrl =
    "https://th.bing.com/th/id/OIP.WSOWvBSKXb9Jlx_zb_vxvwHaHa?w=512&h=512&rs=1&pid=ImgDetMain";

  // Check if ProfileData exists and has the data property before rendering
  if (!ProfileData || !ProfileData.data) {
    return null; // or render a loading indicator or some default content
  }

  return (
    <>
      <Topbar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          background: "linear-gradient(to right, #ffedff, #fff)",
        }}
      >
        <Card
          style={{
            background: "linear-gradient(to right, #2d0031, #4d0054)",
            width: "60%",
            // height: "200%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <CardContent>
            <Typography
              variant="h2"
              component="div"
              style={{ marginBottom: "70px", color: "#ffffff" }}
            >
              Profile
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: "20px" }}>
                <img
                  src={imageUrl}
                  alt="Profile Image"
                  style={{
                    width: "100%",
                    maxWidth: "140px",
                    borderRadius: "8px",
                  }}
                />
                <Typography
                  variant="h3"
                  component="div"
                  style={{ color: "#ffffff" }}
                >
                  {ProfileData.data.name}
                </Typography>
              </div>
              <div style={{ textAlign: "left", marginLeft: "50px" }}>
                <Typography
                  variant="h5"
                  component="div"
                  style={{ color: "#ffffff", marginBottom: "20px" }}
                >
                  Employee Code: {ProfileData.data.empCode}
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  style={{ color: "#ffffff", marginBottom: "20px" }}
                >
                  Reporting Manager: {ProfileData.data.rm}
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  style={{ color: "#ffffff", marginBottom: "20px" }}
                >
                  Territory: {ProfileData.data.territory}
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  style={{ color: "#ffffff", marginBottom: "20px" }}
                >
                  State: {ProfileData.data.state}
                </Typography>
              </div>
            </div>
            <Typography
              variant="h3"
              style={{ marginTop: "70px" }}
              component="div"
            >
              <Link to="/change-password">
                <Button size="small" style={{ color: "#ffffff" }}>
                  Change Password
                </Button>
              </Link>
            </Typography>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default Profile;
