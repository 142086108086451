import * as React from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import "leaflet/dist/leaflet.css";
import "react-toastify/dist/ReactToastify.css";
import InputBase from "@mui/material/InputBase";
import Topbar from "../global/Topbar";
import { ToastContainer, toast } from "react-toastify";
import { fetchViewVisits } from "../../Redux/Slice/Visits/ViewVisitsSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Payments() {
  const dispatch = useDispatch();
  const Visits = useSelector((state) => state.ViewVisits.ViewVisitsData?.data);
  const loading = useSelector((state) => state.ViewVisits.isLoader);
  const error = useSelector((state) => state.ViewVisits.isError);
  const [searchTerm, setSearchTerm] = React.useState("");
  const filteredData =
    Visits?.filter((entry) => entry.visits.payment_status === "Yes") ?? [];

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [page, setPage] = React.useState(0);

  React.useEffect(() => {
    dispatch(fetchViewVisits());
  }, [dispatch]);

  const searchData = filteredData?.filter(
    (visitData) =>
      (visitData.visits.concerned_person?.toLowerCase() ?? "").includes(
        searchTerm
      ) ||
      (visitData.visits.firm_name?.toLowerCase() ?? "").includes(searchTerm)
  );

  const handleSearch = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const slicedData = searchData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Topbar />
      <div style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5px",
            background: "linear-gradient(to right, #4d0054, #91009e)",
            border: "1px solid #ddd",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0)",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <h4 style={{ color: "#ffffff" }}>Payments Details</h4>

          <InputBase
            sx={{ ml: 2, width: "220px" }}
            placeholder="Search by Firm or Concerned Person"
            onChange={handleSearch}
            style={{
              color: "#000",
              paddingLeft: "20px",
              padding: "3px",
              paddingRight: "5px",
              borderRadius: "5px",
              backgroundColor: "#fff",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              outline: "none",
              transition: "background-color 0.3s, box-shadow 0.3s",
            }}
          />
        </div>

        <ToastContainer position="bottom-right" autoClose={3000} />
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : filteredData && filteredData.length > 0 ? (
          <React.Fragment>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Sl. No.
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Category
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Firm Name
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Concerned Person
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Payment Method
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Amount
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Bill No
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Cheque No
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Bank Name
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Transfer Id
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Payment Date
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slicedData.map((entry, index) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell>
                          {index + 1 + page * rowsPerPage}
                        </StyledTableCell>
                        <StyledTableCell>
                          {entry.visits.category}
                        </StyledTableCell>
                        <StyledTableCell>
                          {entry.visits.firm_name}
                        </StyledTableCell>
                        <StyledTableCell>
                          {entry.visits.concerned_person}
                        </StyledTableCell>
                        <StyledTableCell>
                          {entry.visits.payment_method}
                        </StyledTableCell>
                        <StyledTableCell>{entry.visits.amount}</StyledTableCell>
                        <StyledTableCell>{entry.visits.billNo}</StyledTableCell>
                        <StyledTableCell>
                          {entry.visits.chequeNo}
                        </StyledTableCell>
                        <StyledTableCell>
                          {entry.visits.bankName}
                        </StyledTableCell>
                        <StyledTableCell>
                          {entry.visits.transferId}
                        </StyledTableCell>
                        <StyledTableCell>
                          {entry.visits.payment_date
                            .split("-")
                            .reverse()
                            .join("-")}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, -1]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rows per page"
            />
          </React.Fragment>
        ) : (
          <div style={{ padding: "20px" }}>No Visits available</div>
        )}
      </div>
    </>
  );
}
