import { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Dashboard from "./scenes/dashboard";
import Sidebar from "./scenes/global/Sidebar";
import Login from "./scenes/Login/login";
import { Provider } from "react-redux";
import store from "./Redux/store";
import ViewPjp from "./scenes/Pjp/ViewPjp";
import Architect from "./scenes/Architect/Architect";
import Builder from "./scenes/Builder/Builder";
import Contractor from "./scenes/Contractor/Contractor";
import Competitor from "./scenes/Competitor/Competitor";
import Others from "./scenes/Others/Others";
import Dealer from "./scenes/Dealer/Dealer";
import Subdealer from "./scenes/SubDealer/Subdealer";
import Visits from "./scenes/Visits/Visits";
import Payments from "./scenes/Payments/Payments";
import ForgetPassword from "./scenes/Login/ForgotPassword";
import ResetPassword from "./scenes/Login/ResetPassword";
import Profile from "./scenes/Profile/Profile";
import ChangePassword from "./scenes/ChangePassword/ChangePassword";
import VisitsOnMap from "./scenes/Visits/VisitsOnMap";
import SidebarAdmin from "./scenes/global/SidebarAdmin";
import Users from "./scenes/Admin/User/Users";
import PjpAdmin from "./scenes/Admin/PJP/ViewPjp";
import AdminArchitect from "./scenes/Admin/Architect/Architect";
import BuilderAdmin from "./scenes/Admin/Builder/Builder";
import AdminContractor from "./scenes/Admin/Contractor/Contractor";
import AdminCompetitor from "./scenes/Admin/Competitor/Competitor";
import AdminOther from "./scenes/Admin/Others/Others";
import AdminVisits from "./scenes/Admin/Visits/Visits";
import MonthlyVisits from "./scenes/Admin/Visits/MonthlyVisits";
import WeeklyVisits from "./scenes/Admin/Visits/WeeklyVisits";
import SuperDealer from "./scenes/AdminSuper/Dealer/Dealer";
import SuperSubDealer from "./scenes/AdminSuper/SubDealer/Subdealer";
import SidebarSuperAdmin from "./scenes/global/SideBarSuperAdmin";
import AdminDealer from "./scenes/Admin/Dealer/Dealer";
import AdminSubDealer from "./scenes/Admin/SubDealer/Subdealer";
import VisitsOnMapAdmin from "./scenes/Admin/Visits/VisitsOnMap";
import DashboardAdmin from "./scenes/Admin/dashboard";
import TrackEmp from "./scenes/Admin/User/TrackEmp";
import Projects from "./scenes/Projects/Projects";
import AdminReport from "./scenes/Admin/Reports/Reports";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar] = useState(true);
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";
  const isForgetPasswordPage = location.pathname === "/forgot-password";
  const isResetPasswordPage = location.pathname.startsWith("/password-reset");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (
      !token &&
      !isLoginPage &&
      !isForgetPasswordPage &&
      !isResetPasswordPage
    ) {
      // Redirect to login if token is not available
      window.location.href = "/login";
    }
  }, [isLoginPage, isForgetPasswordPage, isResetPasswordPage]);

  // Get role ID from local storage
  const roleId = localStorage.getItem("role_id");

  return (
    <Provider store={store}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app" style={{ display: "flex", height: "100%" }}>
            {!isLoginPage &&
              !isForgetPasswordPage &&
              !isResetPasswordPage &&
              (roleId == "1" ? (
                <SidebarAdmin />
              ) : roleId == "0" ? (
                <SidebarSuperAdmin />
              ) : (
                <Sidebar />
              ))}
            <div
              className="content"
              style={{
                flex: 1,
                overflowY: "auto",
                transition: "margin 0.2s ease", // Optional: add a transition effect
              }}
            >
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgetPassword />} />
                <Route
                  path="/password-reset/:token"
                  element={<ResetPassword />}
                />
                <Route path="/" element={<Dashboard />} />
                <Route path="/viewpjp" element={<ViewPjp />} />
                <Route path="/dealer" element={<Dealer />} />
                <Route path="/subdealer" element={<Subdealer />} />
                <Route path="/architect" element={<Architect />} />
                <Route path="/builder" element={<Builder />} />
                <Route path="/contractor" element={<Contractor />} />
                <Route path="/competitor" element={<Competitor />} />
                <Route path="/others" element={<Others />} />
                <Route path="/visits" element={<Visits />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/payments" element={<Payments />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route path="/visits-on-map" element={<VisitsOnMap />} />
                {/* admin */}
                <Route path="/admin/dashboard" element={<DashboardAdmin />} />
                <Route path="/users" element={<Users />} />
                <Route path="/admin/pjp" element={<PjpAdmin />} />
                <Route path="/admin/architect" element={<AdminArchitect />} />
                <Route
                  path="/admin/builder-contractor"
                  element={<BuilderAdmin />}
                />
                <Route path="/admin/technician" element={<AdminContractor />} />
                <Route path="/admin/competitor" element={<AdminCompetitor />} />
                <Route path="/admin/others" element={<AdminOther />} />
                <Route path="/admin/visits" element={<AdminVisits />} />
                <Route
                  path="/admin/monthly-visits"
                  element={<MonthlyVisits />}
                />
                <Route path="/admin/weekly-visits" element={<WeeklyVisits />} />
                <Route path="/admin/Distributor" element={<AdminDealer />} />
                <Route path="/admin/dealer" element={<AdminSubDealer />} />
                <Route path="/admin/map" element={<VisitsOnMapAdmin />} />
                <Route path="/admin/track-user" element={<TrackEmp />} />
                <Route path="/admin/projects" element={<Projects />} />
                <Route path="/admin/report" element={<AdminReport />} />
                {/* super admin */}
                <Route
                  path="/super-admin/distributor"
                  element={<SuperDealer />}
                />
                <Route
                  path="/super-admin/dealer"
                  element={<SuperSubDealer />}
                />
              </Routes>
            </div>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </Provider>
  );
}

export default App;
