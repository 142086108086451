import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./Slice/LoginSlice";
import authMiddleware from "./authMiddleware";
import ViewPjpSlice from "./Slice/Pjp/ViewPjpSlice";
import ViewCategorySlice from "./Slice/Category/ViewCategorySlice";
import ViewAllDealerSlice from "./Slice/AllDealer/ViewAllDealerSlice";
import ViewProfileSlice from "./Slice/Profile/ViewProfileSlice";
import ViewDashboardSlice from "./Slice/Dashboard/ViewDashboardSlice";
import ViewCategorySliceById from "./Slice/Category/ViewCategoryByIdSlice";
import ViewClientSlice from "./Slice/Client/ViewClientSlice";
import ViewVisitsSlice from "./Slice/Visits/ViewVisitsSlice";
import ViewDealerSlice from "./Slice/Dealer/ViewDealerSlice";
import ViewSubdealerSlice from "./Slice/Subdealer/ViewSubdealerSlice";
import ViewUserSlice from "./Slice/Users/ViewUserSlice";
import ViewAdminPjpSlice from "./Slice/Admin/Pjp/ViewAdminPjpSlice";
import ViewAdminClientsSlice from "./Slice/Admin/Clients/ViewAdminClientsSlice";
import ViewAdminVisitsSlice from "./Slice/Admin/Visits/ViewAdminVisitsSlice";
import ViewAdminDealerSlice from "./Slice/Admin/Dealer/ViewAdminDealerSlice";
import ViewAdminSubdealerSlice from "./Slice/Admin/Subdealer/ViewAdminSubdealerSlice";
import ViewProjectsSlice from "./Slice/Admin/Projects/ViewProjectsSlice";
import ViewAllClientDataSlice from "./Slice/Admin/Report/ViewAllClientDataSlice";

const getInitialToken = () => {
  const initialToken = localStorage.getItem("token") || null;

  return initialToken;
};

let store;

try {
  store = configureStore({
    reducer: {
      auth: AuthReducer,
      ViewPjp: ViewPjpSlice,
      ViewCategory: ViewCategorySlice,
      ViewAllDealer: ViewAllDealerSlice,
      ViewProfile: ViewProfileSlice,
      ViewDashboard: ViewDashboardSlice,
      ViewCategoryById: ViewCategorySliceById,
      // ========================
      ViewClient: ViewClientSlice,
      ViewVisits: ViewVisitsSlice,
      ViewDealer: ViewDealerSlice,
      ViewSubdealer: ViewSubdealerSlice,

      // admin===============
      ViewUser: ViewUserSlice,
      ViewAdminPjp: ViewAdminPjpSlice,
      ViewAdminClients: ViewAdminClientsSlice,
      ViewAdminVisits: ViewAdminVisitsSlice,
      ViewAdminProjects: ViewProjectsSlice,
      ViewAllClientData: ViewAllClientDataSlice,

      // Super admin=============

      ViewAdminDealer: ViewAdminDealerSlice,
      ViewAdminSubdealer: ViewAdminSubdealerSlice,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(authMiddleware),
    preloadedState: {
      auth: {
        token: getInitialToken(),
        // other auth state fields
      },
    },
  });

  console.log("Redux store created successfully:", store);
} catch (error) {
  console.error("Error creating Redux store:", error);
}

export default store;
